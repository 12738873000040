import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import { useEffect, useReducer, useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import Loader from "../../../../MainComponents/Loader.jsx";

function GivechanceStudent() {
  const [values, setValues] = useState({});
  const [dateValue, setDateValue] = useState("");
  const navigate = useNavigate();

  const { sessionID, stage } = useParams();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitEditStudentSession": {
        return {
          ...state,
          submitEditStudentSession: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitEditStudentSession: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  const [LectureAddEditSuccess, LectureAddEditErrors, submitLoading] = useAxios(
    process.env.REACT_APP_ADMIN_GIVE_CHANGE_SESSION_STUDENT_API,
    "POST",
    state.submitEditStudentSession.flag,
    state.submitEditStudentSession.dependency,
    state.submitEditStudentSession.data,
    true
  );

  const [LectureInfo, LectureInfoErrors, LectureInfoLoadin] = useAxios(
    `${process.env.REACT_APP_ADMIN_CHANGE_SESSION_STUDENT_INFO_API}/${sessionID}/${stage}`,
    "GET",
    "GET",
    sessionID
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched", values });

  useEffect(() => {
    if (LectureAddEditSuccess) {
      navigate(-1);
      reset();
    }
  }, [LectureAddEditSuccess, navigate, reset]);

  const onSubmit = (data) => {
    const finalData = {
      ...data,
      id: sessionID,
    };

    dispatch({
      type: "setSubmitEditStudentSession",
      payload: {
        flag: "EditStudentSession",
        dependency: !state.submitEditStudentSession.dependency,
        data: finalData,
      },
    });
  };

  if (LectureInfoLoadin) {
    return <Loader />;
  }

  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Change Session | Nawar Academy"></HelmetTags>
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          Student Change Session
        </h2>

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl   p-16 shadow-lg shadow-blue-200/50"
        >
          {/** Name stage filed */}
          <div className="flex w-full items-center justify-between gap-10 md:flex-col">
            <div className="name flex w-full flex-col items-start justify-center gap-2 md:w-full">
              <label htmlFor="lecture_id">Session</label>
              <select
                name="lecture_id"
                id="lecture_id"
                {...register("lecture_id", { required: true })}
              >
                {LectureInfo?.data?.map((lecture) => (
                  <option value={lecture.key}>{lecture.title}</option>
                ))}
              </select>

              {LectureAddEditErrors &&
                LectureAddEditErrors?.response?.data?.errors?.lecture_id && (
                  <p className="w-full text-[12px] text-blue-900">
                    {
                      LectureAddEditErrors?.response?.data?.errors
                        ?.lecture_id[0]
                    }
                  </p>
                )}
            </div>
          </div>

          <button
            disabled={
              !isValid || (submitLoading && state.submitEditStudentSession.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitEditStudentSession.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>Change Session</p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default GivechanceStudent;
