import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Loader } from "../../../../MainComponents";

function EditStudentSession({ edit }) {
  const [values, setValues] = useState({});
  const [dateValue, setDateValue] = useState("");
  const navigate = useNavigate();

  const { recordID } = useParams();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitEditStudentSession": {
        return {
          ...state,
          submitEditStudentSession: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitEditStudentSession: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  const [LectureAddEditSuccess, LectureAddEditErrors, submitLoading] = useAxios(
    process.env.REACT_APP_ADMIN_EDIT_STUDENT_SESSION_INFO_API,
    "POST",
    state.submitEditStudentSession.flag,
    state.submitEditStudentSession.dependency,
    state.submitEditStudentSession.data,
    true
  );

  const [LectureInfo, LectureInfoErrors, LectureInfoLoadin] = useAxios(
    `${process.env.REACT_APP_ADMIN_GET_STUDENT_SESSION_INFO_API}/${recordID}`,
    "GET",
    recordID,
    recordID
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched", values });

  // Convert 'YYYY-MM-DD HH:MM:SS' to 'YYYY-MM-DDTHH:MM'
  const formatToDatetimeLocal = (dateStr) => {
    const [datePart, timePart] = dateStr.split(" ");
    return `${datePart}T${timePart.slice(0, 5)}`; // Slicing to get 'HH:MM'
  };

  useEffect(() => {
    if (LectureInfo) {
      let temp = LectureInfo.data.historyinfo;
      setValues({
        ...temp,
        expire_at: formatToDatetimeLocal(temp.expire_at),
        done_quiz: temp.done_quiz === 1 ? true : false,
        done_homework: temp.done_homework === 1 ? true : false,
      });
    }
  }, [LectureInfo]);

  useEffect(() => {
    if (LectureAddEditSuccess) {
      navigate(-1);
      reset();
    }
  }, [LectureAddEditSuccess, navigate, reset]);

  const handleInputChange = (id, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const onSubmit = (data) => {
    // Collect video data
    const videoData = LectureInfo?.data?.videos.map((video) => ({
      id: video.id,
      views: data[video.id] ? data[video.id] : video.views,
    }));

    const finalData = {
      ...data,
      id: recordID,
      done_quiz: data?.done_quiz === true ? 1 : 0,
      done_homework: data?.done_homework === true ? 1 : 0,
      expire_at: moment(data?.expire_at).format("YYYY-MM-DD HH:mm:ss"), // Format expire_at to the API format
      videos: videoData,
    };

    dispatch({
      type: "setSubmitEditStudentSession",
      payload: {
        flag: "EditStudentSession",
        dependency: !state.submitEditStudentSession.dependency,
        data: finalData,
      },
    });
  };

  if (LectureInfoLoadin) return <Loader />;

  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Lectures | Nawar Academy"></HelmetTags>
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          Edit Student Session
        </h2>

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl   p-16 shadow-lg shadow-blue-200/50"
        >
          {/** Name stage filed */}
          {LectureInfo?.data?.videos.map((video) => (
            <div
              className="flex w-full items-center justify-between gap-10 md:flex-col"
              key={video.id}
            >
              <div className="name flex w-1/2 flex-col items-start justify-center gap-2 md:w-full">
                <label htmlFor={video.id}>{video.name}</label>
              </div>
              <div className="name flex w-1/2 flex-col items-start justify-center gap-2 md:w-full">
                <label htmlFor={video.id}>Views</label>
                <input
                  className="signin-inputs signin-input w-full pl-4"
                  type="number"
                  id={video.id}
                  placeholder="Views"
                  name={video.id}
                  autoComplete="on"
                  defaultValue={video.views}
                  min={0}
                  onChange={(e) => handleInputChange(video.id, e.target.value)}
                />

                {LectureAddEditErrors &&
                  LectureAddEditErrors?.response?.data?.errors?.views && (
                    <p className="w-full text-[12px] text-blue-900">
                      {LectureAddEditErrors?.response?.data?.errors?.views[0]}
                    </p>
                  )}
              </div>
            </div>
          ))}

          {/** visibility homework quiz fields */}
          {edit && (
            <>
              <div className="flex  w-full items-start gap-10 flex-col border-t-2 border-secondary pt-10 mt-6">
                <div className="name flex w-full flex-col items-start justify-center  gap-2  md:w-full">
                  <label htmlFor="expire_at">Expiration Date</label>
                  <input
                    className="signin-inputs signin-input w-full pl-4"
                    type="datetime-local"
                    id="expire_at"
                    placeholder="Expiration Date"
                    name="expire_at"
                    autoComplete="on"
                    {...register("expire_at", {
                      required: true,
                      validate: (value) => {
                        const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
                        return (
                          regex.test(value) ||
                          "Please enter a valid date and time"
                        );
                      },
                    })}
                  />

                  {errors.expire_at && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {errors.expire_at.type === "required" &&
                        "Please fill out this field"}
                      {errors.expire_at.type === "pattern" &&
                        "Only English letters are allowed"}
                      {errors.expire_at.type === "maxLength" &&
                        "The maximum number of characters is 40 characters"}
                      {errors.expire_at.type === "minLength" &&
                        "Please write at least 3 characters"}
                      {errors.expire_at.message || "Invalid date format"}
                    </p>
                  )}

                  {
                    //!-------server errors -----

                    LectureAddEditErrors &&
                      LectureAddEditErrors?.response?.data?.errors
                        ?.expire_at && (
                        <p className="w-full  text-[12px] text-blue-900  ">
                          {
                            LectureAddEditErrors?.response?.data?.errors
                              ?.expire_at[0]
                          }
                        </p>
                      )
                  }
                </div>
                <div className="visibility flex w-full flex-col items-end justify-center gap-2 md:w-full">
                  <div className="flex w-full items-center gap-10">
                    <label className="visibility-switch">
                      <input
                        className=""
                        id="done_quiz"
                        name="done_quiz"
                        {...register("done_quiz", {
                          required: false,
                        })}
                        type="checkbox"
                      />
                      <span className="visibility-slider">
                        <svg
                          className="slider-icon"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <path fill="none" d="m4 16.5 8 8 16-16"></path>
                        </svg>
                      </span>
                    </label>
                    <div className="order-1">
                      <label
                        className="w-full cursor-pointer truncate"
                        htmlFor="done_quiz"
                      >
                        Done Quiz
                      </label>
                    </div>
                  </div>

                  {errors.done_quiz && (
                    <p className="text-[12px] text-blue-900 ">
                      {errors.done_quiz.type === "required" &&
                        "Please fill out this field"}
                    </p>
                  )}
                  {
                    //!-------server errors -----

                    LectureAddEditErrors &&
                      LectureAddEditErrors?.response?.data?.errors
                        ?.done_quiz && (
                        <p className="w-full  text-[12px] text-blue-900  ">
                          {
                            LectureAddEditErrors?.response?.data?.errors
                              ?.done_quiz[0]
                          }
                        </p>
                      )
                  }
                </div>
                <div className="visibility flex w-full flex-col items-end justify-center gap-2 md:w-full">
                  <div className="flex w-full items-center gap-10">
                    <label className="visibility-switch">
                      <input
                        className=""
                        id="done_homework"
                        name="done_homework"
                        {...register("done_homework", {
                          required: false,
                        })}
                        type="checkbox"
                      />
                      <span className="visibility-slider">
                        <svg
                          className="slider-icon"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <path fill="none" d="m4 16.5 8 8 16-16"></path>
                        </svg>
                      </span>
                    </label>
                    <div className="order-1">
                      <label
                        className="w-full cursor-pointer truncate"
                        htmlFor="done_homework"
                      >
                        Done Homework
                      </label>
                    </div>
                  </div>

                  {errors.done_homework && (
                    <p className="text-[12px] text-blue-900 ">
                      {errors.done_homework.type === "required" &&
                        "Please fill out this field"}
                    </p>
                  )}
                  {
                    //!-------server errors -----

                    LectureAddEditErrors &&
                      LectureAddEditErrors?.response?.data?.errors
                        ?.done_homework && (
                        <p className="w-full  text-[12px] text-blue-900  ">
                          {
                            LectureAddEditErrors?.response?.data?.errors
                              ?.done_homework[0]
                          }
                        </p>
                      )
                  }
                </div>
              </div>
            </>
          )}

          <button
            disabled={
              !isValid || (submitLoading && state.submitEditStudentSession.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitEditStudentSession.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>Edit Student Session</p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default EditStudentSession;
