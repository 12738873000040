import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthState } from "../../../MainComponents/GlobalContext";

const StudentForm = ({
  edit,
  register,
  StudentEditErrors,
  state,
  errors,
  getValues,
  dispatch,
  StudentInfo,
  watchLevel,
  watchPlace,
  watchActivationCode,
}) => {
  const AuthState = useAuthState();
  return (
    <>
      {!edit && (
        <iframe
          width="100%"
          height="400"
          src="https://www.youtube.com/embed/ms_QqMjr_SM?si=PvQ9ziMV5bez1S46"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      )}
      {edit && (
        <>
          <p>Created At: {StudentInfo?.data[0]?.created_at}</p>
          <p
            className={`
            ${StudentInfo?.data[0]?.status === "Active" && "text-[green]"}
            ${StudentInfo?.data[0]?.status === "Request" && "text-[yellow]"}
            ${StudentInfo?.data[0]?.status === "Out" && "text-[black]"}
            ${StudentInfo?.data[0]?.status === "Blocked" && "text-[red]"}

            `}
          >
            Status: {StudentInfo?.data[0]?.status}
          </p>
        </>
      )}
      {/** Name filed */}
      <div className="flex w-full items-start justify-between gap-6 md:flex-col ">
        {/** First Name filed */}
        <div className=" flex w-1/2 flex-col items-start justify-center  gap-2  md:w-full">
          <label className="text-start flex items-center gap-2" htmlFor="fname">
            First name
          </label>
          <input
            className={` signin-inputs signin-input  w-full pl-4 ${
              errors.fname || state.errors.fname ? "!border-[#f00]" : null
            } `}
            type="text"
            id="fname"
            placeholder="First name"
            name="fname"
            autoComplete="on"
            {...register("fname", {
              required: true,
              pattern: /^[A-Za-z\s]+$/u,
              maxLength: 15,
              minLength: 3,
            })}
          />
          {!edit && (
            <p className="w-full text-end text-[12px] text-[#f00]  ">
              باللغه الانجليزيه فقط
            </p>
          )}
          {errors.fname && (
            <p className="w-full text-start text-[14px] text-blue-900  ">
              {errors.fname.type === "required" && "Please fill out this field"}
              {errors.fname.type === "pattern" &&
                "Only English letters are allowed"}
              {errors.fname.type === "maxLength" &&
                "The maximum number of characters is 15 characters"}
              {errors.fname.type === "minLength" &&
                "Please write at least 3 characters"}
            </p>
          )}

          {
            //!-------server errors -----

            state.errors && state.errors.fname && (
              <p className="w-full text-start text-[14px] text-blue-900  ">
                {state.errors.fname[0]}
              </p>
            )
          }
          {
            //!-------Editing server errors -----

            StudentEditErrors?.response?.data?.errors &&
              StudentEditErrors?.response?.data?.errors?.fname && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {StudentEditErrors?.response?.data.errors.fname[0]}
                </p>
              )
          }
        </div>

        {/** Second Name filed */}
        <div className=" flex w-1/2 flex-col items-start justify-center  gap-2 md:w-full">
          <label className="text-start flex items-center gap-2" htmlFor="sname">
            Second name{" "}
          </label>
          <input
            className={` signin-inputs signin-input  w-full pl-4 ${
              errors.sname || state.errors.sname ? "!border-[#f00]" : null
            } `}
            type="text"
            id="sname"
            placeholder="Second name"
            name="sname"
            autoComplete="on"
            {...register("sname", {
              required: true,
              pattern: /^[A-Za-z\s]+$/u,

              maxLength: 15,
              minLength: 3,
            })}
          />
          {!edit && (
            <p className="w-full text-end text-[12px] text-[#f00]  ">
              باللغه الانجليزيه فقط
            </p>
          )}
          {errors.sname && (
            <p className="w-full text-start text-[14px] text-blue-900  ">
              {errors.sname.type === "required" && "Please fill out this field"}
              {errors.sname.type === "pattern" &&
                "Only English letters are allowed"}
              {errors.sname.type === "maxLength" &&
                "The maximum number of characters is 15 characters"}
              {errors.sname.type === "minLength" &&
                "Please write at least 3 characters"}
            </p>
          )}

          {
            //!-------server errors -----

            state.errors && state.errors.sname && (
              <p className="w-full text-start text-[14px] text-blue-900  ">
                {state.errors.sname[0]}
              </p>
            )
          }
          {
            //!-------Editing server errors -----

            StudentEditErrors?.response?.data?.errors &&
              StudentEditErrors?.response?.data?.errors?.sname && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {StudentEditErrors?.response?.data.errors.sname[0]}
                </p>
              )
          }
        </div>

        {/** Third Name filed */}
        <div className="flex w-1/2 flex-col items-start justify-center  gap-2   md:w-full">
          <label className="text-start flex items-center gap-2" htmlFor="lname">
            Last name
          </label>
          <input
            className={` signin-inputs signin-input  w-full pl-4 ${
              errors.lname || state.errors.lname ? "!border-[#f00]" : null
            } `}
            type="text"
            id="lname"
            placeholder="Last name"
            name="lname"
            autoComplete="on"
            {...register("lname", {
              required: true,
              pattern: /^[A-Za-z\s]+$/u,
              maxLength: 15,
              minLength: 3,
            })}
          />
          {!edit && (
            <p className="w-full text-end text-[12px] text-[#f00]  ">
              باللغه الانجليزيه فقط
            </p>
          )}
          {errors.lname && (
            <p className="w-full text-start text-[14px] text-blue-900  ">
              {errors.lname.type === "required" && "Please fill out this field"}
              {errors.lname.type === "pattern" &&
                "Only English letters are allowed"}
              {errors.lname.type === "maxLength" &&
                "The maximum number of characters is 15 characters"}
              {errors.lname.type === "minLength" &&
                "Please write at least 3 characters"}
            </p>
          )}
          {
            //!-------server errors -----

            state.errors && state.errors.lname && (
              <p className="w-full text-start text-[14px] text-blue-900  ">
                {state.errors.lname[0]}
              </p>
            )
          }
          {
            //!-------Editing server errors -----

            StudentEditErrors?.response?.data?.errors &&
              StudentEditErrors?.response?.data?.efrrors?.lname && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {StudentEditErrors?.response?.data.errors.lname[0]}
                </p>
              )
          }
        </div>
      </div>

      <div className="flex w-full items-start justify-between gap-6 md:flex-col ">
        <div
          className={`flex ${
            edit ? "w-1/2" : "w-full"
          } md:w-full flex-col items-start justify-center gap-2`}
        >
          <label className="text-start flex items-center gap-2" htmlFor="email">
            Email
          </label>
          <input
            className={` signin-inputs signin-input  w-full pl-4 ${
              errors.email || state.errors.email ? "!border-[#f00]" : null
            } `}
            type="email"
            id="email"
            placeholder="Email"
            name="email"
            autoComplete="off"
            {...register("email", {
              required: true,
              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            })}
          />
          {!edit && (
            <p className="w-full text-end text-[12px] text-[#f00]  ">
              @ لابد ان يحتوي الايميل علي
            </p>
          )}
          {errors.email && (
            <p className="mt-2 w-full text-start text-[12px] text-blue-900">
              {errors.email.type === "required" && "Please fill out this field"}
              {errors.email.type === "pattern" &&
                "Please enter a valid email address"}
            </p>
          )}

          {
            //!-------server errors -----

            state.errors && state.errors.email && (
              <p className="w-full text-start text-[14px] text-blue-900  ">
                {state.errors.email[0]}
              </p>
            )
          }
          {
            //!-------Editing server errors -----

            StudentEditErrors?.response?.data?.errors &&
              StudentEditErrors?.response?.data?.errors?.email && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {StudentEditErrors?.response?.data.errors.email[0]}
                </p>
              )
          }
        </div>
        {edit && (
          <div
            className={`flex ${
              edit ? "w-1/2" : "w-full"
            } md:w-full flex-col items-start justify-center gap-2`}
          >
            <label
              className="text-start flex items-center gap-2"
              htmlFor="twins_email"
            >
              Twins Email
            </label>
            <input
              className={` signin-inputs signin-input  w-full pl-4 ${
                errors.twins_email || state.errors.twins_email
                  ? "!border-[#f00]"
                  : null
              } `}
              type="twins_email"
              id="twins_email"
              placeholder="Twins Email"
              name="twins_email"
              autoComplete="off"
              {...register("twins_email", {
                required: false,
                pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              })}
            />

            {errors.twins_email && (
              <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                {errors.twins_email.type === "required" &&
                  "Please fill out this field"}
                {errors.twins_email.type === "pattern" &&
                  "Please enter a valid Twins Email address"}
              </p>
            )}

            {
              //!-------server errors -----

              state.errors && state.errors.twins_email && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {state.errors.twins_email[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.twins_email && (
                  <p className="w-full text-start text-[14px] text-blue-900  ">
                    {StudentEditErrors?.response?.data.errors.twins_email[0]}
                  </p>
                )
            }
          </div>
        )}
      </div>

      {/** Father's Mobile Numbers fields */}
      <div className="flex flex-row  w-full items-start justify-between gap-6 md:flex-col">
        {/** student WhatsUp field */}
        <div className="w-1/3  md:w-full">
          <div className="  flex w-full flex-col items-start justify-center gap-2">
            <label
              className="text-start flex items-center gap-2"
              htmlFor="mobile"
            >
              Student number{" "}
            </label>
            <div className="relative w-full">
              <input
                className={` signin-inputs signin-input  w-full pl-4 ${
                  errors.mobile || state.errors.mobile ? "!border-[#f00]" : null
                } `}
                type="number"
                id="mobile"
                placeholder="Student number"
                name="mobile"
                autoComplete="off"
                {...register("mobile", {
                  required: true,
                  pattern: /^[\d]{11}/,
                  maxLength: 11,
                  validate: (value) =>
                    value !== getValues("mobilep2") &&
                    value !== getValues("mobilep1"),
                })}
              />
              <FontAwesomeIcon
                className="absolute bottom-1/2 right-4 h-6  w-7 translate-y-1/2"
                icon={faWhatsapp}
              />
            </div>
            {!edit && (
              <p className="w-full text-end text-[12px] text-[#f00]  ">
                لابد ان لا يحتوي علي اي رموز ويبدا ب 01 ويدون مسافات
              </p>
            )}
          </div>
          {errors.mobile && (
            <p className="mt-2 w-full text-start text-[12px] text-blue-900">
              {errors.mobile.type === "required" &&
                "Please fill out this field"}
              {errors.mobile.type === "pattern" &&
                "Please enter an 11-digit phone number"}
              {errors.mobile.type === "maxLength" &&
                "Please enter an 11-digit phone number"}
              {errors.mobile.type === "validate" &&
                "Phone number must be unique"}
            </p>
          )}

          {
            //!-------server errors -----

            state.errors && state.errors.mobile && (
              <p className="w-full text-start text-[14px] text-blue-900  ">
                {state.errors.mobile[0]}
              </p>
            )
          }
          {
            //!-------Editing server errors -----

            StudentEditErrors?.response?.data?.errors &&
              StudentEditErrors?.response?.data?.errors?.mobile && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {StudentEditErrors?.response?.data.errors.mobile[0]}
                </p>
              )
          }
        </div>

        {/** Father's WhatsUp field 1 */}
        <div className={`${edit ? "w-1/2" : "w-1/3"}    md:w-full`}>
          <div className="  flex w-full flex-col items-start justify-center gap-2">
            <label
              className="text-start flex items-center gap-2"
              htmlFor="mobilep1"
            >
              Father Mobile
            </label>
            <div className="relative w-full">
              <input
                className={` signin-inputs signin-input  w-full pl-4 ${
                  errors.mobilep1 || state.errors.mobilep1
                    ? "!border-[#f00]"
                    : null
                } `}
                type="number"
                id="mobilep1"
                placeholder="Father Mobile"
                name="mobilep1"
                autoComplete="on"
                {...register("mobilep1", {
                  required: true,
                  pattern: /^[\d]{11}/,
                  maxLength: 11,
                  validate: (value) =>
                    value !== getValues("mobile") &&
                    value !== getValues("mobilep2"),
                })}
              />
              <FontAwesomeIcon
                className="absolute bottom-1/2 right-4 h-6  w-7 translate-y-1/2"
                icon={faWhatsapp}
              />
            </div>
            {!edit && (
              <p className="w-full text-end text-[12px] text-[#f00]  ">
                لابد ان لا يحتوي علي اي رموز ويبدا ب 01 ويدون مسافات
              </p>
            )}
          </div>
          {errors.mobilep1 && (
            <p className="mt-2 w-full text-start text-[12px] text-blue-900">
              {errors.mobilep1.type === "required" &&
                "Please fill out this field"}
              {errors.mobilep1.type === "pattern" &&
                "Please enter an 11-digit phone number"}
              {errors.mobilep1.type === "maxLength" &&
                "Please enter an 11-digit phone number"}
              {errors.mobilep1.type === "validate" &&
                "Phone number must be unique"}
            </p>
          )}

          {
            //!-------server errors -----

            state.errors && state.errors.mobilep1 && (
              <p className="w-full text-start text-[14px] text-blue-900  ">
                {state.errors.mobilep1[0]}
              </p>
            )
          }
          {
            //!-------Editing server errors -----

            StudentEditErrors?.response?.data?.errors &&
              StudentEditErrors?.response?.data?.errors?.mobilep1 && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {StudentEditErrors?.response?.data.errors.mobilep1[0]}
                </p>
              )
          }
        </div>

        {/** Father's WhatsUp field 2 */}

        <div className={`${edit ? "w-1/2" : "w-1/3"}   md:w-full`}>
          <div className="  flex w-full flex-col items-start justify-center gap-2">
            <label className="text-start" htmlFor="mobilep2">
              Mother Mobile
            </label>
            <div className="relative w-full">
              <input
                className={` signin-inputs signin-input  w-full pl-4 ${
                  errors.mobilep2 || state.errors.mobilep2
                    ? "!border-[#f00]"
                    : null
                } `}
                type="number"
                id="mobilep2"
                placeholder="Mother Mobile"
                name="mobilep2"
                autoComplete="on"
                {...register("mobilep2", {
                  required: false,
                  pattern: /^[\d]{11}/,
                  maxLength: 11,
                  validate: (value) =>
                    value !== getValues("mobilep1") &&
                    value !== getValues("mobile"),
                })}
              />
              <FontAwesomeIcon
                className="absolute bottom-1/2 right-4 h-6  w-7 translate-y-1/2"
                icon={faWhatsapp}
              />
            </div>
            {!edit && (
              <p className="w-full text-end text-[12px] text-[#f00]  ">
                لابد ان لا يحتوي علي اي رموز ويبدا ب 01 ويدون مسافات
              </p>
            )}
          </div>
          {errors.mobilep2 && (
            <p className="mt-2 w-full text-start text-[12px] text-blue-900">
              {errors.mobilep2.type === "required" &&
                "Please fill out this field"}
              {errors.mobilep2.type === "pattern" &&
                "Please enter an 11-digit phone number"}
              {errors.mobilep2.type === "maxLength" &&
                "Please enter an 11-digit phone number"}
              {errors.mobilep2.type === "validate" &&
                "Please enter a different phone number"}
            </p>
          )}

          {
            //!-------server errors -----

            state.errors && state.errors.mobilep2 && (
              <p className="w-full text-start text-[14px] text-blue-900  ">
                {state.errors.mobilep2[0]}
              </p>
            )
          }
          {
            //!-------Editing server errors -----

            StudentEditErrors?.response?.data?.errors &&
              StudentEditErrors?.response?.data?.errors?.mobilep2 && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {StudentEditErrors?.response?.data.errors.mobilep2[0]}
                </p>
              )
          }
        </div>
      </div>

      {/**  passwords fields */}
      {!edit && (
        <div className="flex w-full items-start justify-between gap-6 md:flex-col">
          {/** Password  */}
          <div className="w-1/2    md:w-full">
            <div className="relative flex flex-col items-start justify-center gap-2 ">
              <label
                className="text-start flex items-center gap-2"
                htmlFor="password"
              >
                Password
              </label>
              <div
                //!input password relative
                className="relative-hide relative h-auto w-full"
              >
                <input
                  id="password"
                  className={` signin-inputs signin-input  w-full pl-4 ${
                    errors.password || state.errors.password
                      ? "!border-[#f00]"
                      : null
                  } `}
                  type={`${state.hide ? "text" : "password"}`}
                  placeholder="Password"
                  name="password"
                  autoComplete="on"
                  {...register("password", {
                    required: true,
                    maxLength: 25,
                    minLength: 6,
                  })}
                />

                <div
                  //!eye icons
                  onClick={() =>
                    dispatch({
                      type: "setHide",
                    })
                  }
                  className="hide-pass absolute bottom-1/2 right-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
                >
                  {state.hide ? (
                    <FontAwesomeIcon className="h-full w-full" icon={faEye} />
                  ) : (
                    <FontAwesomeIcon
                      className="h-full w-full"
                      icon={faEyeSlash}
                    />
                  )}
                </div>
              </div>
              <p className="w-full text-end text-[12px] text-[#f00]  ">
                لابد ان يحتوي علي 6 حروف علي الاقل{" "}
              </p>
            </div>
            {errors.password && (
              <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                {errors.password.type === "required" &&
                  "Please fill out this field"}
                {errors.password.type === "maxLength" &&
                  "The maximum number of characters is 25 characters"}
                {errors.password.type === "minLength" &&
                  "The minimum number of characters is 6 characters"}
              </p>
            )}

            {
              //!-------server errors -----

              state.errors && state.errors.password && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {state.errors.password[0]}
                </p>
              )
            }
          </div>

          {/** Confirm Password  */}
          <div className="w-1/2   md:w-full">
            <div className="relative flex flex-col items-start justify-center gap-2   ">
              <label
                className="text-start flex items-center gap-2"
                htmlFor="password_confirmation"
              >
                Confirm the password
              </label>

              <div
                //!input password relative
                className="relative-hide relative h-auto w-full"
              >
                <input
                  id="password_confirmation"
                  className={` signin-inputs signin-input  w-full pl-4 ${
                    errors.password_confirmation ||
                    state.errors.password_confirmation
                      ? "!border-[#f00]"
                      : null
                  } `}
                  type={`${state.hide ? "text" : "password"}`}
                  placeholder="Confirm the password"
                  name="password_confirmation"
                  autoComplete="on"
                  {...register("password_confirmation", {
                    required: true,
                    maxLength: 25,
                    minLength: 6,
                    validate: (value) => value === getValues("password"),
                  })}
                />

                <div
                  //!eye icons
                  onClick={() =>
                    dispatch({
                      type: "setHide",
                    })
                  }
                  className="hide-pass absolute bottom-1/2 right-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
                >
                  {state.hide ? (
                    <FontAwesomeIcon className="h-full w-full" icon={faEye} />
                  ) : (
                    <FontAwesomeIcon
                      className="h-full w-full"
                      icon={faEyeSlash}
                    />
                  )}
                </div>
              </div>
              <p className="w-full text-end text-[12px] text-[#f00]  ">
                لابد ان يحتوي علي 6 حروف علي الاقل{" "}
              </p>
            </div>

            {errors.password_confirmation && (
              <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                {errors.password_confirmation.type === "required" &&
                  "Please fill out this field"}
                {errors.password_confirmation.type === "maxLength" &&
                  "The maximum number of characters is 25 characters"}
                {errors.password_confirmation.type === "minLength" &&
                  "The minimum number of characters is 6 characters"}
                {errors.password_confirmation.type === "validate" &&
                  "Passwords do not match"}
              </p>
            )}

            {
              //!-------server errors -----

              state.errors && state.errors.password_confirmation && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {state.errors.password_confirmation[0]}
                </p>
              )
            }
          </div>
        </div>
      )}

      {/** Points and Amounts */}
      {edit && (
        <div className="flex flex-row  w-full items-start justify-between gap-6 md:flex-col ">
          <div className="  flex w-1/2 md:w-full flex-col items-start justify-center gap-2">
            <label className="text-start" htmlFor="amount">
              Amount
            </label>
            <input
              className={` signin-inputs signin-input  w-full pl-4 ${
                errors.amount || state.errors.amount ? "!border-[#f00]" : null
              } `}
              type="number"
              id="amount"
              placeholder="Amount"
              name="amount"
              autoComplete="on"
              min={0}
              disabled={
                !AuthState.userData?.admin?.roles[0]?.name === "superadmin"
              }
              {...register("amount", {
                required: true,
              })}
            />
            {errors.amount && (
              <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                {errors.amount.type === "required" &&
                  "Please fill out this field"}
              </p>
            )}

            {
              //!-------server errors -----

              state.errors && state.errors.amount && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {state.errors.amount[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.amount && (
                  <p className="w-full text-start text-[14px] text-blue-900  ">
                    {StudentEditErrors?.response?.data.errors.amount[0]}
                  </p>
                )
            }
          </div>
          <div className="  flex w-1/2 md:w-full flex-col items-start justify-center gap-2">
            <label className="text-start" htmlFor="discount">
              Discount
            </label>
            <input
              className={` signin-inputs signin-input  w-full pl-4 ${
                errors.discount || state.errors.discount
                  ? "!border-[#f00]"
                  : null
              } `}
              type="number"
              id="discount"
              placeholder="Discount"
              name="discount"
              min={0}
              autoComplete="on"
              {...register("discount", {
                required: false,
              })}
            />
            {errors.discount && (
              <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                {errors.discount.type === "required" &&
                  "Please fill out this field"}
              </p>
            )}

            {
              //!-------server errors -----

              state.errors && state.errors.discount && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {state.errors.discount[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.discount && (
                  <p className="w-full text-start text-[14px] text-blue-900  ">
                    {StudentEditErrors?.response?.data.errors.discount[0]}
                  </p>
                )
            }
          </div>
        </div>
      )}

      {/** Governorate and City fields */}
      <div className="flex flex-row w-full items-start justify-between gap-6 md:flex-col">
        {/** City filed */}
        <div className=" flex w-1/2  flex-col items-start justify-center gap-2 md:w-full ">
          <label
            className="text-start flex items-center gap-2"
            htmlFor="governorate"
          >
            Governorate
          </label>
          <select
            className={` signin-inputs signin-input  w-full pl-4 ${
              errors.governorate || state.errors.governorate
                ? "!border-[#f00]"
                : null
            } `}
            name="governorate"
            id="governorate"
            // defaultValue="القاهرة"
            {...register("governorate", { required: true })}
          >
            <option value="" disabled>
              Select Gove
            </option>
            {state?.states?.map((city) => (
              <option
                key={city.id}
                value={city.state}
                // selected={
                //   StudentInfo?.data[0]?.states
                //     ? StudentInfo?.data[0]?.states === city.state
                //     : city.id === 1
                // }
              >
                {city.state}
              </option>
            ))}
          </select>

          {errors.governorate && (
            <p className="w-full text-start text-[14px] text-blue-900  ">
              {errors.governorate.type === "required" &&
                "Please fill out this field"}
            </p>
          )}
          {
            //!-------server errors -----

            state.errors && state.errors.governorate && (
              <p className="w-full text-start text-[14px] text-blue-900  ">
                {state.errors.governorate[0]}
              </p>
            )
          }
          {
            //!-------Editing server errors -----

            StudentEditErrors?.response?.data?.errors &&
              StudentEditErrors?.response?.data?.errors?.governorate && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {StudentEditErrors?.response?.data.errors.governorate[0]}
                </p>
              )
          }
        </div>
        {state?.cities && (
          <div className=" flex w-1/2 flex-col items-start justify-center gap-2 md:w-full ">
            <label
              className="text-start flex items-center gap-2"
              htmlFor="area"
            >
              Cities
            </label>
            <select
              className={` signin-inputs signin-input  w-full pl-4 ${
                errors.area || state.errors.area ? "!border-[#f00]" : null
              } `}
              name="area"
              id="area"
              // defaultValue={StudentInfo?.data[0]?.area || 1}
              {...register("area", { required: true })}
            >
              {state?.cities?.map((city) => (
                <option
                  key={city.id}
                  id={city.id}
                  value={city.id}
                  // selected={
                  //   StudentInfo?.data[0]?.area
                  //     ? StudentInfo?.data[0]?.area === city.id
                  //     : city.id === 1
                  // }
                >
                  {city.city_name_ar}
                </option>
              ))}
            </select>
            {!edit && (
              <p className="w-full text-end text-[12px] text-[#f00]  ">
                لا بد من اختيار المدينة{" "}
              </p>
            )}
            {errors.area && (
              <p className="w-full text-start text-[14px] text-blue-900  ">
                {errors.area.type === "required" &&
                  "Please fill out this field"}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.area && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {state.errors.area[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.area && (
                  <p className="w-full text-start text-[14px] text-blue-900  ">
                    {StudentEditErrors?.response?.data.errors.area[0]}
                  </p>
                )
            }
          </div>
        )}
      </div>

      {/** Stage filed */}
      <div className="flex flex-row-reverse w-full items-end justify-between gap-16 md:flex-col md:items-start md:gap-6 ">
        {/** sciences or math fields */}

        <div className="flex w-1/2 flex-col  items-center    md:w-full">
          {watchLevel === "3" || watchLevel === 3 ? (
            <>
              <label className="text-start" htmlFor="school">
                Section (القسم العلمي)
              </label>
              <div className="flex w-full items-end justify-between gap-8 pb-1 sm:flex-col">
                {/** sciences */}

                <div className=" flex  items-center justify-center gap-2 ">
                  <input
                    className="cursor-pointer"
                    type="radio"
                    id="sciences"
                    value="science"
                    {...register("section", { required: true })}
                  />
                  <label
                    className="cursor-pointer text-start"
                    htmlFor="sciences"
                  >
                    Sciences (علوم)
                  </label>
                </div>
                {/** math  */}

                <div className=" flex  items-center justify-center gap-2 ">
                  <input
                    className="cursor-pointer"
                    type="radio"
                    id="math"
                    value="math"
                    {...register("section", { required: true })}
                  />
                  <label className="cursor-pointer text-start" htmlFor="math">
                    Math (رياضة)
                  </label>
                </div>
              </div>
              {errors.section && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {errors.section.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.section && (
                  <p className="w-full text-start text-[14px] text-blue-900  ">
                    {state.errors.section[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.section && (
                    <p className="w-full text-start text-[14px] text-blue-900  ">
                      {StudentEditErrors?.response?.data.errors.section[0]}
                    </p>
                  )
              }
            </>
          ) : (
            ""
          )}
        </div>
        {/** Stage  */}

        <div className=" flex w-1/2 flex-col items-start justify-center gap-2 md:w-full">
          <label className="text-start flex items-center gap-2" htmlFor="stage">
            Stage
          </label>
          <select
            className={` signin-inputs signin-input  w-full pl-4 ${
              errors.stage || state.errors.stage ? "!border-[#f00]" : null
            } `}
            name="stage"
            id="stage"
            {...register("stage", { required: true })}
          >
            <option value="1">First secondary</option>
            <option value="2">Second secondary</option>
            <option value="3">Third secondary</option>
          </select>
          {errors.stage && (
            <p className="w-full text-start text-[14px] text-blue-900  ">
              {errors.stage.type === "required" && "Please fill out this field"}
            </p>
          )}
          {
            //!-------server errors -----

            state.errors && state.errors.stage && (
              <p className="w-full text-start text-[14px] text-blue-900  ">
                {state.errors.stage[0]}
              </p>
            )
          }
          {
            //!-------Editing server errors -----

            StudentEditErrors?.response?.data?.errors &&
              StudentEditErrors?.response?.data?.errors?.stage && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {StudentEditErrors?.response?.data.errors.stage[0]}
                </p>
              )
          }
        </div>
      </div>

      {/** groups and center or group  fields */}
      <div className="flex flex-row w-full items-start justify-between gap-6 md:flex-col md:gap-6">
        <div className=" flex w-1/2 md:w-full flex-col items-start justify-center gap-2 ">
          <label
            className="text-start flex items-center gap-2"
            htmlFor="is_online"
          >
            Attendance
          </label>
          <select
            className=" signin-inputs signin-input"
            name="is_online"
            id="is_online"
            {...register("is_online", { required: true })}
          >
            <option value="1">Online</option>
            <option value="0">Center</option>
          </select>

          {errors.is_online && (
            <p className="w-full text-start text-[14px] text-blue-900  ">
              {errors.is_online.type === "required" &&
                "Please fill out this field"}
            </p>
          )}
          {
            //!-------server errors -----

            state.errors && state.errors.is_online && (
              <p className="w-full text-start text-[14px] text-blue-900  ">
                {state.errors.is_online[0]}
              </p>
            )
          }
          {
            //!-------Editing server errors -----

            StudentEditErrors?.response?.data?.errors &&
              StudentEditErrors?.response?.data?.errors?.is_online && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {StudentEditErrors?.response?.data.errors.is_online[0]}
                </p>
              )
          }
        </div>

        {/** center or group */}
        {state.groups.filter((group) => +group.is_online === +watchPlace) && (
          <div className=" flex w-1/2 md:w-full flex-col items-start justify-center gap-2 ">
            <label
              className="text-start flex items-center gap-2"
              htmlFor="group"
            >
              Group
            </label>
            <select
              className={` signin-inputs signin-input  w-full pl-4 ${
                errors.group || state.errors.group ? "!border-[#f00]" : null
              } `}
              name="group"
              id="group"
              {...register("group", { required: true })}
            >
              <option value="" disabled selected>
                Select Group
              </option>
              {watchPlace === "1" || watchPlace === 1 ? (
                <>
                  {state.groups
                    .filter((group) => +group.is_online === +watchPlace)
                    .map((group) => (
                      <>
                        <option value={group.id}>{group.name}</option>
                      </>
                    ))}
                </>
              ) : null}
              {watchPlace === "0" || watchPlace === 0 ? (
                <>
                  {state.groups
                    .filter((group) => +group.is_online === +watchPlace)
                    .filter((group) => +group.stage === +watchLevel)
                    .map((group) => (
                      <>
                        <option value={group.id}>{group.name}</option>
                      </>
                    ))}
                </>
              ) : null}
            </select>
            {!edit && (
              <p className="w-full text-end text-[12px] text-[#f00]  ">
                لابد من اختيار الجروب
              </p>
            )}
            {errors.group && (
              <p className="w-full text-start text-[14px] text-blue-900  ">
                {errors.group.type === "required" &&
                  "Please fill out this field"}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.group && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {state.errors.group[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.group && (
                  <p className="w-full text-start text-[14px] text-blue-900  ">
                    {StudentEditErrors?.response?.data.errors.group[0]}
                  </p>
                )
            }
          </div>
        )}
      </div>

      {/** active and magnetic_id fields */}
      {edit && (
        <>
          <div className="flex flex-row w-full items-center justify-between gap-16 md:flex-col md:gap-6">
            {/**magnetic_id */}
            <div className=" flex w-full flex-col items-start justify-center  gap-2 md:w-full">
              <label className="text-start" htmlFor="magnetic_id">
                card
              </label>
              <input
                className=" signin-inputs signin-input pl-4    w-full"
                type="text"
                id="magnetic_id"
                placeholder="card"
                name="magnetic_id"
                autoComplete="on"
                {...register("magnetic_id", {
                  pattern: /^[A-Za-z0-9-_]*$/,
                  minLength: 3,
                })}
              />
              {errors.magnetic_id && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {errors.magnetic_id.type === "required" &&
                    "Please fill out this field"}
                  {errors.magnetic_id.type === "pattern" &&
                    "Only English letters and numbers are allowed"}
                  {errors.magnetic_id.type === "maxLength" &&
                    "The maximum number of characters is 20 characters"}
                  {errors.magnetic_id.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.magnetic_id && (
                  <p className="w-full text-start text-[14px] text-blue-900  ">
                    {state.errors.magnetic_id[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.magnetic_id && (
                    <p className="w-full text-start text-[14px] text-blue-900  ">
                      {StudentEditErrors?.response?.data.errors.magnetic_id[0]}
                    </p>
                  )
              }
            </div>
          </div>
          <div className="flex flex-row w-full items-center justify-between gap-16 md:flex-col md:gap-6">
            {/**magnetic_id */}
            <div className=" flex w-full flex-col items-start justify-center  gap-2 md:w-full">
              <label className="text-start" htmlFor="permissions">
                Permissions
              </label>
              <input
                className=" signin-inputs signin-input pl-4    w-full"
                type="text"
                id="permissions"
                placeholder="Permissions"
                name="permissions"
                autoComplete="on"
                min={0}
                {...register("permissions", {
                  // pattern: /^[A-Za-z0-9-_]*$/,
                })}
              />

              {
                //!-------server errors -----

                state.errors && state.errors.permissions && (
                  <p className="w-full text-start text-[14px] text-blue-900  ">
                    {state.errors.permissions[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.permissions && (
                    <p className="w-full text-start text-[14px] text-blue-900  ">
                      {StudentEditErrors?.response?.data.errors.permissions[0]}
                    </p>
                  )
              }
            </div>
          </div>
        </>
      )}

      {/** School and Activation Code fields */}
      <div className="flex w-full items-start justify-between gap-12 md:flex-col  md:gap-6">
        {/** School  */}
        <div className="flex w-full flex-col items-start justify-center gap-2">
          <label className="text-start" htmlFor="school">
            School
          </label>
          <input
            className={` signin-inputs signin-input  w-full pl-4 ${
              errors.school || state.errors.school ? "!border-[#f00]" : null
            } `}
            type="text"
            id="school"
            placeholder="School"
            name="school"
            autoComplete="on"
            {...register("school", {
              required: true,
              maxLength: 35,
              minLength: 4,
            })}
          />
          {errors.school && (
            <p className="w-full text-start text-[14px] text-blue-900  ">
              {errors.school.type === "required" &&
                "Please fill out this field"}

              {errors.school.type === "maxLength" &&
                "The maximum number of characters is 35 characters"}
              {errors.school.type === "minLength" &&
                "Please write at least 4 characters"}
            </p>
          )}
          {
            //!-------server errors -----

            state.errors && state.errors.school && (
              <p className="w-full text-start text-[14px] text-blue-900  ">
                {state.errors.school[0]}
              </p>
            )
          }
          {
            //!-------Editing server errors -----

            StudentEditErrors?.response?.data?.errors &&
              StudentEditErrors?.response?.data?.errors?.school && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {StudentEditErrors?.response?.data.errors.school[0]}
                </p>
              )
          }
        </div>
      </div>

      {/* Gender  */}
      <div className="flex w-1/2 flex-col  items-center    md:w-full">
        <label className="text-start" htmlFor="school">
          Gender
        </label>
        <div className="flex w-full items-end justify-between gap-8 pb-1 sm:flex-col-reverse">
          <div className=" flex  items-center justify-center gap-2 ">
            <input
              className="cursor-pointer"
              type="radio"
              id="male"
              value="male"
              defaultChecked={StudentInfo?.data[0]?.gender === 1 ? true : false}
              {...register("gender", { required: true })}
            />
            <label className="cursor-pointer text-start" htmlFor="male">
              Male
            </label>
          </div>

          <div className=" flex  items-center justify-center gap-2 ">
            <input
              className="cursor-pointer"
              type="radio"
              id="female"
              value="female"
              defaultChecked={StudentInfo?.data[0]?.gender === 0 ? true : false}
              {...register("gender", { required: true })}
            />
            <label className="cursor-pointer text-start" htmlFor="female">
              Female
            </label>
          </div>
        </div>
        {errors.gender && (
          <p className="w-full text-start text-[14px] text-blue-900  ">
            {errors.gender.type === "required" && "Please fill out this field"}
          </p>
        )}
        {
          //!-------server errors -----

          state.errors && state.errors.gender && (
            <p className="w-full text-start text-[14px] text-blue-900  ">
              {state.errors.gender[0]}
            </p>
          )
        }
        {
          //!-------Editing server errors -----

          StudentEditErrors?.response?.data?.errors &&
            StudentEditErrors?.response?.data?.errors?.gender && (
              <p className="w-full text-start text-[14px] text-blue-900  ">
                {StudentEditErrors?.response?.data.errors.gender[0]}
              </p>
            )
        }
      </div>

      {/* Activation code */}

      {!edit && (
        <div className="flex w-1/2 flex-col  items-center    md:w-full">
          <label className="text-start" htmlFor="school">
            Activation Code
          </label>
          <div className="flex w-full items-end justify-between gap-8 pb-1 sm:flex-col-reverse">
            <div className=" flex  items-center justify-center gap-2 ">
              <input
                className="cursor-pointer"
                type="radio"
                id="have"
                value="have"
                checked
                {...register("activation_code_radio", { required: true })}
              />
              <label className="cursor-pointer text-start" htmlFor="have">
                I have
              </label>
            </div>

            <div className="hidden  items-center justify-center gap-2 ">
              <input
                className="cursor-pointer"
                type="radio"
                id="havenot"
                value="havenot"
                hidden
                {...register("activation_code_radio", { required: true })}
              />
              <label className="cursor-pointer text-start" htmlFor="havenot">
                I haven't
              </label>
            </div>
          </div>
        </div>
      )}

      {watchActivationCode === "have" && (
        <div className=" flex w-full flex-col items-start justify-center  gap-2 mb-6">
          <label
            className="text-start flex items-center gap-2"
            htmlFor="activation_code"
          >
            Activation Code
          </label>
          <input
            className={` signin-inputs signin-input  w-full pl-4 ${
              errors.activation_code || state.errors.activation_code
                ? "!border-[#f00]"
                : null
            } `}
            type="text"
            id="activation_code"
            placeholder="Activation Code"
            name="activation_code"
            autoComplete="on"
            {...register("activation_code", {
              required: true,
              maxLength: 15,
              minLength: 3,
            })}
          />
          {!edit && (
            <p className="w-full text-end text-[12px] text-[#f00]  ">
              اكتب كود التفعيل اللي حصلت عليه من رقم 01032240876 ( لسنة تالتة )
              او رقم 01095946327 ( سنة اولي وتاتية)
            </p>
          )}
          {errors.activation_code && (
            <p className="w-full text-start text-[14px] text-blue-900  ">
              {errors.activation_code.type === "required" &&
                "Please fill out this field"}
              {errors.activation_code.type === "maxLength" &&
                "The maximum number of characters is 15 characters"}
              {errors.activation_code.type === "minLength" &&
                "Please write at least 3 characters"}
            </p>
          )}

          {
            //!-------server errors -----

            state.errors && state.errors.activation_code && (
              <p className="w-full text-start text-[14px] text-blue-900  ">
                {state.errors.activation_code[0]}
              </p>
            )
          }
          {
            //!-------Editing server errors -----

            StudentEditErrors?.response?.data?.errors &&
              StudentEditErrors?.response?.data?.errors?.activation_code && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {StudentEditErrors?.response?.data.errors.activation_code[0]}
                </p>
              )
          }
        </div>
      )}

      <div className=" flex w-full flex-col items-start justify-center  gap-2 mb-6">
        <label
          className="text-start flex items-center gap-2"
          htmlFor="profile_fb"
        >
          Facebook Account
        </label>
        <input
          className={` signin-inputs signin-input  w-full pl-4 ${
            errors.profile_fb || state.errors.profile_fb
              ? "!border-[#f00]"
              : null
          } `}
          type="text"
          id="profile_fb"
          placeholder="Facebook Account"
          name="profile_fb"
          autoComplete="on"
          {...register("profile_fb", {
            required: true,
            pattern: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i,
            maxLength: 2048,
            minLength: 5,
          })}
        />
        {!edit && (
          <p className="w-full text-end text-[12px] text-[#f00]  ">
            لينك اكاونت الفيس بوك تقدر تحصل عليه من داخل اكاونت الفيس الخاص بيك
          </p>
        )}
        {errors.profile_fb && (
          <p className="w-full text-start text-[14px] text-blue-900  ">
            {errors.profile_fb.type === "required" &&
              "Please fill out this field"}
            {errors.profile_fb.type === "pattern" && "Please enter a valid URL"}
            {errors.profile_fb.type === "maxLength" && "The URL is too long"}
            {errors.profile_fb.type === "minLength" && "The URL is too short"}
          </p>
        )}

        {
          //!-------server errors -----

          state.errors && state.errors.profile_fb && (
            <p className="w-full text-start text-[14px] text-blue-900  ">
              {state.errors.profile_fb[0]}
            </p>
          )
        }
        {
          //!-------Editing server errors -----

          StudentEditErrors?.response?.data?.errors &&
            StudentEditErrors?.response?.data?.errors?.activation_code && (
              <p className="w-full text-start text-[14px] text-blue-900  ">
                {StudentEditErrors?.response?.data.errors.activation_code[0]}
              </p>
            )
        }
      </div>

      {/** User Image  */}
      {!edit && (
        <div className="flex w-full flex-col items-start gap-2">
          <label className="w-full  " htmlFor="profile_pic">
            Profile Picture (الصورة الشخصية)
          </label>

          <input
            id="profile_pic"
            className={`signin-inputs signin-input w-full !pt-[12px] ${
              errors.profile_pic || state.errors.profile_pic
                ? "!border-[#f00]"
                : null
            } `}
            type="file"
            accept="image/jpg, image/jpeg, image/png, image/webp"
            name="profile_pic"
            {...register("profile_pic", {
              required: true,
              validate: (value) => !(value[0]?.size > 5000000),
            })}
          />

          {errors.profile_pic && (
            <p className="mt-2 text-xs text-blue-500">
              {errors.profile_pic.type === "required" && "Add profile picture"}
              {errors.profile_pic.type === "validate" && "Maximum size is 5 MB"}
            </p>
          )}
          {
            //!-------server errors -----

            StudentEditErrors &&
              StudentEditErrors?.response?.data?.errors?.profile_pic && (
                <p className="text-xs text-blue-500  ">
                  {StudentEditErrors?.response?.data?.errors?.profile_pic[0]}
                </p>
              )
          }
        </div>
      )}

      {!edit && watchLevel === "3" ? (
        <div className="flex w-full flex-col items-start gap-2">
          <label className="w-full  " htmlFor="certificate_pic">
            National ID Or Birth Certificate (البطاقة الشخصية او شهادة ميلاد )
          </label>

          <input
            id="certificate_pic"
            className={`signin-inputs signin-input w-full !pt-[12px] ${
              errors.certificate_pic || state.errors.certificate_pic
                ? "!border-[#f00]"
                : null
            } `}
            type="file"
            accept="image/jpg, image/jpeg, image/png, image/webp"
            name="certificate_pic"
            {...register("certificate_pic", {
              required: true,
              validate: (value) => !(value[0]?.size > 5000000),
            })}
          />

          {errors.certificate_pic && (
            <p className="mt-2 text-xs text-blue-500">
              {errors.certificate_pic.type === "required" &&
                "Add profile picture"}
              {errors.certificate_pic.type === "validate" &&
                "Maximum size is 5 MB"}
            </p>
          )}
          {
            //!-------server errors -----

            StudentEditErrors &&
              StudentEditErrors?.response?.data?.errors?.certificate_pic && (
                <p className="text-xs text-blue-500  ">
                  {
                    StudentEditErrors?.response?.data?.errors
                      ?.certificate_pic[0]
                  }
                </p>
              )
          }
        </div>
      ) : null}

      {!edit && (
        <div className="w-full flex flex-col py-8 px-4 rounded-[25px] border-2 border-secondary text-right">
          <h2 className="text-black text-3xl text-center mb-12">
            تعليمات هامة جدا لطالب السنتر و الاونلاين
          </h2>
          <p className="text-xl text-[gray] font-bold mb-4 leading-10">
            ١ - يلتزم الطالب بتسجيل كل البيانات علي السايت صحيحة حتي لا يتم مسح
            الحساب
          </p>
          <p className="text-xl text-[gray] font-bold mb-4 leading-10">
            ٢- يلتزم الطالب بأن يحتفظ باسم المستخدم والرقم السري ولا يسمح لحد
            اخر الدخول من حسابه حتي لا يتم مسح الحساب
          </p>
          <p className="text-xl text-[gray] font-bold mb-4 leading-10">
            ٣-مسموح للطالب دخول السايت من جهاز واحد فقط
          </p>
          <p className="text-xl text-[gray] font-bold mb-4 leading-10">
            ٤- يلتزم طالب السنتر باحضار كارت الدخول كل حصة حتي يتمكن من مشاهدة
            فيديو الهومورك علي السايت
          </p>
          <p className="text-xl text-[gray] font-bold mb-4 leading-10">
            ٥ - يلتزم طالب الاونلاين بحضرور جميع الحصص ولا يسمح له بتخطي حصة{" "}
          </p>
          <p className="text-xl text-[gray] font-bold mb-4 leading-10">
            ٦ - يلتزم طالب السنتر بحضور جميع الحصص وفي حالة الغياب يلزم تعويض
            الحصة اونلاين (لا يستطيع طالب السنتر شراء حصص الاونلاين الا بعد
            الرجوع للاسيستنت علي رسايل صفحة الفيس )
          </p>
          <p className="text-xl text-[gray] font-bold mb-4 leading-10">
            ٧- يلتزم طالب الاونلاين بعدم شراء اكواد فائضة عن حاجته لأنه لا يتم
            استرجاع اكواد نهائيا
          </p>
          <p className="text-xl text-[gray] font-bold mb-4 leading-10">
            ٨ - يلتزم الطالب بعدم تسجيل او تسريب المحاضرات حتي لا يتم مسح الحساب{" "}
          </p>
          <p className="text-xl text-[gray] font-bold mb-4 leading-10">
            ٩ - حصة الاونلاين بتنزل علي السايت يوم الجمعة صباحا وترفع من السايت
            يوم الخميس الساعة ١٢ مساء ويلتزم طالب الاونلاين بشراء الحصة قبل موعد
            رفعها من السايت
          </p>
          <p className="text-xl text-[gray] font-bold mb-4 leading-10">
            ١٠ - لطالب الاونلاين ثلاث مشاهدات علي الفيديو خلال خمس ايام فقط من
            موعد شرائها وبعدها لا يمكن فتح الحصة مرة اخري
          </p>
          <p className="text-xl text-[gray] font-bold mb-4 leading-10">
            ١١ - غير مسموح بفتح سيشن مره اخره بعد انتهاء وقتها{" "}
          </p>
          <p className="text-xl text-[gray] font-bold mb-4 leading-10">
            ١٢ - عند غياب الطالب حصتين متواصلتين عن السنتر او عن حصة الاونلاين
            يتم عمل بلوك للاكاونت علي الموقع
          </p>
          <p className="text-xl text-[gray] font-bold mb-4 leading-10">
            ١٣ - حصص المراجعة لا يتم رفعها من السايت ويمكن شرائها في اي وقت{" "}
          </p>
          <p className="text-xl text-[gray] font-bold mb-4 leading-10">
            ١٤ - لازم نلتزم بحضور الامتحانات الشهرية و في حالة انك مامتحنتش لا
            يمكن تحضر الحصة اللي بعدها سنتر او اونلاين
          </p>

          <p className="text-xl text-[gray] font-bold mb-4 leading-10">
            ١٥ - لازم يلتزم طالب السنتر بالحضور داخل السنتر وفي حالة حضوره حصة
            اونلاين يستكمل باقي الشهر اونلاين ويكون شرط عودته للسنتر اجتياز
            امتحان الشهر في السنتر
          </p>
          <p className="text-xl text-[gray] font-bold mb-4 leading-10">
            ١٦ - حضور الامتحان في السنتر اجباري لجميع طلبة السنتر و الاونلاين في
            القاهرة والجيزة
          </p>
          <p className="text-xl text-[gray] font-bold mb-4 leading-10">
            ١٧ - للطالب عدد ٢ بلوك خلال العام وبعدها بيتم مسح الاكاونت من الموقع
          </p>
          <p className="text-xl text-[gray] font-bold mb-4 leading-10">
            ١٨ - فيديو الواجب جزء من السيشين ومشاهدته اجبارية{" "}
          </p>
        </div>
      )}
      {!edit && (
        <div className=" flex w-full flex-col  gap-2 mb-6">
          <div className="flex items-center gap-4">
            <input
              className=""
              type="checkbox"
              id="checked"
              name="checked"
              autoComplete="on"
              {...register("checked", {
                required: true,
                maxLength: 15,
                minLength: 3,
              })}
            />
            <label
              className="text-start flex items-center gap-2"
              htmlFor="checked"
            >
              Accept All terms & conditions (اقبل جميع الشروط والآحكام)
            </label>
          </div>
          {errors.checked && (
            <p className="w-full text-start text-[14px] text-blue-900  ">
              {errors.checked.type === "required" &&
                "Please accept terms & conditions"}
              {errors.checked.type === "maxLength" &&
                "The maximum number of characters is 15 characters"}
              {errors.checked.type === "minLength" &&
                "Please write at least 3 characters"}
            </p>
          )}

          {
            //!-------server errors -----

            state.errors && state.errors.checked && (
              <p className="w-full text-start text-[14px] text-blue-900  ">
                {state.errors.checked[0]}
              </p>
            )
          }
          {
            //!-------Editing server errors -----

            StudentEditErrors?.response?.data?.errors &&
              StudentEditErrors?.response?.data?.errors?.checked && (
                <p className="w-full text-start text-[14px] text-blue-900  ">
                  {StudentEditErrors?.response?.data.errors.checked[0]}
                </p>
              )
          }
        </div>
      )}
    </>
  );
};

export default StudentForm;
