import useTable from "../../../../MainComponents/Hooks/useTable.jsx";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faTimesCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

function Students() {
  const [academiclevel, setAcademiclevel] = useState("1");
  const AuthState = useAuthState();

  const table = useTable("students", "", "", academiclevel, "", academiclevel);

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Students | Nawar Academy"></HelmetTags>
      <div className="mb-20 flex flex-col items-end gap-20">
        <div className="w-full flex justify-between items-center gap-5 mb-7">
          <h2 className="text-4xl font-bold md:text-center">All Students</h2>
          <div className="flex items-center gap-5 md:flex-col">
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("")
              )) && (
              <Link
                to="/admin/students/blocked"
                className=" rounded-lg border-2 border-[#f87171] bg-[#f87171] px-2 py-1 font-semibold text-white transition-all duration-300 hover:bg-bg hover:text-delete   active:scale-90 "
              >
                Blocked Students
                <FontAwesomeIcon className="ml-2" icon={faTimesCircle} />
              </Link>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("")
              )) && (
              <Link
                to="/admin/students/deleted"
                className=" rounded-lg border-2 border-[#f87171] bg-[#f87171] px-2 py-1 font-semibold text-white transition-all duration-300 hover:bg-bg hover:text-delete   active:scale-90 "
              >
                Out Students <FontAwesomeIcon className="ml-2" icon={faTrash} />
              </Link>
            )}
          </div>
        </div>
        <div className="flex w-full  gap-10 md:justify-center sm:flex-col-reverse sm:items-center">
          <button
            onClick={() => {
              setAcademiclevel("3");
            }}
            className={` rounded-xl border-2 border-secondary order-3  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academiclevel === "3" && "bg-secondary text-light"
            }`}
          >
            Third grade
          </button>

          <button
            onClick={() => {
              setAcademiclevel("2");
            }}
            className={` rounded-xl border-2 border-secondary order-2  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academiclevel === "2" && "bg-secondary text-light"
            }`}
          >
            Second grade
          </button>
          <button
            onClick={() => {
              setAcademiclevel("1");
            }}
            className={` rounded-xl border-2 border-secondary order-1  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academiclevel === "1" && "bg-secondary text-light"
            }`}
          >
            First grade
          </button>
        </div>
      </div>
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default Students;
