import { useEffect, useMemo, useReducer } from "react";
import useAxios from "./Hooks/useAxios.jsx";
import {
  faArrowRightFromBracket,
  faClipboardQuestion,
  faClipboardUser,
  faShieldHalved,
  faUsers,
  faUser,
  faCommentAlt,
  faChartLine,
  faUserShield,
  faGears,
  faMagnifyingGlass,
  faPercent,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { RiUserFollowLine } from "react-icons/ri";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useAuthDispatch, useAuthState } from "./GlobalContext.jsx";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";

import NavbarLogo from "../assets/navbar-logo.png";
import HomeIcon from "../assets/icons/home.png";
import StudentsIcon from "../assets/icons/students.png";
import CenterIcon from "../assets/icons/center.png";
import LecturesIcon from "../assets/icons/lectures.png";
import RequestsIcon from "../assets/icons/requests.png";
import AssistantIcon from "../assets/icons/assistant.png";
import WhatsappIcon from "../assets/icons/whatsapp.png";
import ExamIcon from "../assets/icons/exam.png";
import ProfileIcon from "../assets/icons/profile.jpg";
import VodafoneIcon from "../assets/icons/vodafone.png";
import HelpIcon from "../assets/icons/help.png";
import TopStudentsIcon from "../assets/icons/top-students.png";
import LiveZommIcon from "../assets/icons/live-zoom.png";
import ReservationIcon from "../assets/icons/reservation.png";
import FinalRevisionIcon from "../assets/icons/final-revision.png";
import CenterSessionIcon from "../assets/icons/center-session.png";
import FinalStepsIcon from "../assets/icons/final-steps.png";
import ThreeDIcon from "../assets/icons/3d.png";
import ResultsIcon from "../assets/icons/results.png";
import MozakraIcon from "../assets/icons/mozakra.png";
import ExamVideosIcon from "../assets/icons/exam-videos.png";

import { Dropdown } from "antd";
import axios from "axios";
import StudentChat from "../Pages/Student/fabs/StudentChat.jsx";
import { faImage } from "@fortawesome/free-regular-svg-icons";

function MainLayout({ admin }) {
  const AuthDispatch = useAuthDispatch();
  const AuthState = useAuthState();
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const initialState = {
    collapse: false,
    showSidebar: false,
    refetch: false,
  };
  function reducer(state, action) {
    switch (action.type) {
      case "SetCollapse": {
        return {
          ...state,
          collapse: !state.collapse,
        };
      }
      case "SetRefetch": {
        return {
          ...state,
          refetch: !state.refetch,
        };
      }
      case "SetShowSidebar": {
        return {
          ...state,
          showSidebar: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  //!--------- get numbers in tabs-------
  const [NumbersInTabs, NumbersInTabsErrors] = useAxios(
    process.env.REACT_APP_ADMIN_NUMBERS_IN_TABS_API,
    "GET",
    admin ? "GET" : "",
    state.refetch,
    "",
    "",
    AuthState.refetchIfAcceptOrDeleteRequest | admin
  );

  function handleSignOut() {
    dispatch({
      type: "SetShowSidebar",
      payload: false,
    });

    localStorage.removeItem("userData");
    AuthDispatch({ type: "setUserData", payload: null });
    navigate(admin ? "/admin/signin" : "/signin");
  }

  const items = [
    {
      label: admin ? (
        <Link to="">Management</Link>
      ) : (
        <p>Parent Id : {AuthState?.userData?.student.parent_id}</p>
      ),
      key: "0",
    },
    {
      label: admin ? (
        <Link to="">Booking</Link>
      ) : (
        <p>Amount : {AuthState?.userData?.student.amount}</p>
      ),
      key: "1",
    },
    {
      label: <Link to={admin ? "/admin/profile" : "/profile"}>My profile</Link>,
      key: "2",
    },
    {
      label: <button onClick={handleSignOut}>Logout</button>,
      key: "3",
    },
  ];

  const adminOrStuedntToken = JSON.parse(localStorage?.userData).admin_token
    ? JSON.parse(localStorage?.userData).admin_token
    : JSON.parse(localStorage?.userData).student_token;
  useMemo(() => {
    // set axios
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + adminOrStuedntToken;
  }, [adminOrStuedntToken]);

  useEffect(() => {
    if (window.innerWidth < 900) {
      dispatch({
        type: "SetCollapse",
      });
    }
  }, []);

  const hadnelClickLink = () => {
    dispatch({
      type: "SetShowSidebar",
      payload: false,
    });
    if (window.innerWidth < 900) {
      dispatch({
        type: "SetCollapse",
      });
    }
  };

  return (
    <section className="flex flex-row-reverse h-[calc(100vh-60px)] relative top-[60px] md:top-0 md:h-screen w-full overflow-hidden bg-light dark:bg-dark dark:text-light md:placeholder md:pt-[60px]">
      <section className=" fixed inset-0 top-0 z-50  flex h-[60px] w-full items-center justify-between bg-secondary px-16 text-light">
        <div className="flex items-center">
          <div className="collapse-btn-wrapper relative mr-4">
            <button
              onClick={() =>
                dispatch({
                  type: "SetCollapse",
                })
              }
              className="text-[20px]"
            >
              {state.collapse ? <MdClose /> : <GiHamburgerMenu />}
            </button>
            {/* <FontAwesomeIcon
              onClick={() =>
                dispatch({
                  type: "SetCollapse",
                })
              }
              className={` absolute right-0 top-20 z-50  translate-x-4 rotate-180 cursor-pointer rounded-full bg-light text-5xl transition duration-200 ease-in-out hover:scale-105 ${
                state.collapse && "rotate-0"
              }`}
              icon={faCircleChevronRight}
            /> */}
          </div>
          <div className="logo h-fit">
            <img
              className="h-16 cursor-pointer transition duration-200 ease-in-out hover:scale-105"
              onClick={() => navigate("/admin/dashboard")}
              src={NavbarLogo}
              alt="logoDark"
            />
          </div>
        </div>
        <div className="flex items-center gap-6">
          {!admin && (
            <>
              {AuthState?.userData?.student?.stage === 3 ? (
                <a
                  href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_NjFmMDI0N2MtMmFlZC00MzUyLThhNjYtODU1OWI2NzI2NjVk%40thread.v2/0?context=%7b%22Tid%22%3a%22bb2d013b-894b-4bd1-b98b-1f09df0c7e1b%22%2c%22Oid%22%3a%22b58e2f1f-90fb-4811-b92c-d6427edf7f0f%22%7d"
                  target="_blank"
                  rel="noreferrer"
                  className="bg-[#f00] py-2 px-4 rounded-xl"
                >
                  Live (9 م) - (م 10)
                </a>
              ) : null}
              {AuthState?.userData?.student?.stage === 2 ? (
                <a
                  href="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fmeetup-join%2F19%3Ameeting_YzZkNDZjZWQtYjlkMi00MjlmLTlmZWYtMGRjYzMzMzllN2Rh%40thread.v2%2F0%3Fcontext%3D%257b%2522Tid%2522%253a%2522bb2d013b-894b-4bd1-b98b-1f09df0c7e1b%2522%252c%2522Oid%2522%253a%2522b58e2f1f-90fb-4811-b92c-d6427edf7f0f%2522%257d%26anon%3Dtrue&type=meetup-join&deeplinkId=bbb397a5-1d03-48b8-8a43-e62364eec3f3&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true"
                  target="_blank"
                  rel="noreferrer"
                  className="bg-[#f00] py-2 px-4 rounded-xl"
                >
                  Live (3 م) - (م 10)
                </a>
              ) : null}
            </>
          )}
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
          >
            <button>
              <img
                src={
                  admin
                    ? ProfileIcon
                    : `https://api.nawaracademy.com/uploads/profile_picture/${AuthState.userData?.student?.profile_pic}`
                }
                className="w-14 h-14 rounded-full"
                alt="profile"
              />
            </button>
          </Dropdown>
        </div>
      </section>

      {/**
       * //!outlet
       *
       */}
      <section className={`outlet h-full w-full overflow-y-auto bg-white`}>
        <Outlet />
      </section>
      {/**
       * //!placeholder
       *
       */}
      <div
        className={`placeholder reletive h-full ${
          state.collapse ? "w-0" : "w-1/5 min-w-[300px]"
        }  transition-all duration-200 ease-in-out md:hidden`}
      ></div>
      {/**
       * //!dash menu fixed
       */}
      <div
        className={`dash-menu-fixed   z-40 ${
          state.collapse ? "left-[-300px]" : "left-0 w-1/5 min-w-[300px] "
        } fixed left-0 
                    h-full w-1/5 transition-all duration-200 ease-in-out ${
                      state.showSidebar
                        ? "md:pointer-events-auto md:w-full md:translate-x-0 md:opacity-100"
                        : ""
                    }`}
      >
        <div className="dash-menu-container  flex  h-full w-full   flex-col items-center  gap-10 overflow-y-auto bg-light py-10">
          <div className="h-fit">
            <img
              className={`h-48 md:h-20 cursor-pointer transition duration-200 ease-in-out hover:scale-105`}
              onClick={() => navigate("/")}
              src="https://api.nawaracademy.com/uploads/assets/logo-dash-light.png"
              alt="logo-dash-light"
            />
          </div>

          {admin && (
            <ul className="dash-menu-items pb-[60px] flex h-fit w-full  flex-col items-center gap-5 ">
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.length > 0) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    to="/admin/dashboard"
                    className={`group flex h-16 w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "justify-bet items-center  px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center">
                      <img src={HomeIcon} className={`mr-5 w-8`} alt="" />
                      <p>Home</p>
                    </div>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some(
                  (permission) =>
                    permission.name.startsWith("student") &&
                    permission.name !== "student_count"
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    to="/admin/students"
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center">
                      <img src={StudentsIcon} className={`mr-5 w-8`} alt="" />{" "}
                      <p>All Students</p>
                    </div>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some(
                  (permission) =>
                    permission.name.startsWith("student") &&
                    permission.name !== "student_count"
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    to="/admin/center"
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center">
                      <img src={CenterIcon} className={`mr-5 w-8`} alt="" />{" "}
                      <p>Center</p>
                    </div>
                    {NumbersInTabs?.data?.center_counter &&
                      (AuthState.userData?.admin?.roles[0]?.name ===
                        "superadmin" ||
                        AuthState.userData?.admin?.permissions?.some(
                          (permission) =>
                            permission.name.startsWith("request_accept")
                        )) && (
                        <p
                          className={`min-w-[60%] numbers flex items-center  justify-center rounded-full p-1 w-full text-end text-white transition-all duration-200 ease-in-out bg-secondary group-hover:bg-light  group-hover:text-secondary`}
                        >
                          {NumbersInTabs?.data?.center_counter}
                        </p>
                      )}
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some(
                  (permission) =>
                    permission.name.startsWith("student") &&
                    permission.name !== "student_count"
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    to="/admin/online"
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center">
                      <img src={CenterIcon} className={`mr-5 w-8`} alt="" />{" "}
                      <p>Online</p>
                    </div>
                    {NumbersInTabs?.data?.online_counter &&
                      (AuthState.userData?.admin?.roles[0]?.name ===
                        "superadmin" ||
                        AuthState.userData?.admin?.permissions?.some(
                          (permission) =>
                            permission.name.startsWith("request_accept")
                        )) && (
                        <p
                          className={`min-w-[60%] numbers flex items-center  justify-center rounded-full p-1 w-full text-end text-white transition-all duration-200 ease-in-out bg-secondary group-hover:bg-light  group-hover:text-secondary`}
                        >
                          {NumbersInTabs?.data?.online_counter}
                        </p>
                      )}
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("code")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    to="/admin/codes"
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center">
                      <FontAwesomeIcon
                        className="mr-5 w-8"
                        icon={faShieldHalved}
                      />
                      <p>Codes</p>
                    </div>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("code")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                    to="/admin/activation-code "
                  >
                    <div className="text-icon  flex w-full items-center">
                      <FontAwesomeIcon
                        className="mr-5 w-5 "
                        icon={faUserShield}
                      />{" "}
                      <p>Activation Codes</p>
                    </div>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("code")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                    to="/admin/activation-code-tracker"
                  >
                    <div className="text-icon  flex w-full items-center">
                      <FontAwesomeIcon
                        className="mr-5 w-5 "
                        icon={faUserShield}
                      />{" "}
                      <p>Activation Codes Tracker</p>
                    </div>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("code")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    to="/admin/vodafone-cash-codes"
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center">
                      <img src={VodafoneIcon} className={`mr-5  w-10`} alt="" />{" "}
                      <p>Vodafone Cash Codes</p>
                    </div>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("code")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    to="/admin/codes-tracker"
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center">
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className="mr-5 w-8"
                      />
                      <p>Code tracking</p>
                    </div>
                  </NavLink>
                </li>
              )}

              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("code")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    to="/admin/vodafone-codes-tracker"
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center">
                      <img src={VodafoneIcon} className="mr-5 w-8" alt="" />
                      <p>Vodafone Code tracking</p>
                    </div>
                  </NavLink>
                </li>
              )}

              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("attendance")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    to="/admin/attendance"
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center">
                      <FontAwesomeIcon
                        className="mr-5 w-8"
                        icon={faClipboardUser}
                      />
                      <p>Attendance</p>
                    </div>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("lecture")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    to="/admin/lectures"
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center">
                      <img src={LecturesIcon} className={`mr-5 w-8`} alt="" />
                      <p>Lectures</p>
                    </div>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("lecture")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    to="/admin/courses"
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center">
                      <img src={LecturesIcon} className={`mr-5 w-8`} alt="" />
                      <p>Courses</p>
                    </div>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("student")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    to="/admin/students-courses"
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center">
                      <img src={LecturesIcon} className={`mr-5 w-8`} alt="" />
                      <p>Students Courses</p>
                    </div>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("exam")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    to="/admin/exams"
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center">
                      <img src={ExamIcon} className={`mr-5  w-8`} alt="" />{" "}
                      <p>Exams</p>
                    </div>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("questionbank")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    to="/admin/question-bank"
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center">
                      <FontAwesomeIcon
                        className="mr-5 w-8"
                        icon={faClipboardQuestion}
                      />
                      <p>Question Bank</p>
                    </div>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("group")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    to="/admin/groups"
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center">
                      <FontAwesomeIcon className="mr-5 w-8" icon={faUsers} />
                      <p>Groups</p>
                    </div>
                  </NavLink>
                </li>
              )}

              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("request")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    to="/admin/requests"
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center">
                      <img src={RequestsIcon} className={`mr-5 w-8`} alt="" />{" "}
                      <p>Requests</p>
                    </div>

                    {NumbersInTabs?.data?.requests_count &&
                      (AuthState.userData?.admin?.roles[0]?.name ===
                        "superadmin" ||
                        AuthState.userData?.admin?.permissions?.some(
                          (permission) =>
                            permission.name.startsWith("request_accept")
                        )) && (
                        <span
                          className={`numbers flex items-center  justify-center rounded-full p-1  ${
                            state.collapse
                              ? "mb-5 h-3   w-3  bg-secondary text-[0px]"
                              : "h-10  w-10 min-w-[25px] min-h-[25px]  text-2xl"
                          }  text-secondary transition-all duration-200 ease-in-out group-hover:bg-light amd:mb-5 amd:h-3 amd:w-3  amd:min-w-[8px] amd:min-h-[8px] amd:bg-secondary amd:text-[0px] md:mb-0 md:h-10 md:w-10 md:min-w-[25px] md:min-h-[25px] md:bg-transparent md:text-2xl`}
                        >
                          {NumbersInTabs?.data?.requests_count}
                        </span>
                      )}
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("student_discount")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    to="/admin/discount-students"
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center">
                      <FontAwesomeIcon
                        icon={faPercent}
                        className={`mr-5 w-8`}
                        alt=""
                      />{" "}
                      <p>discount's students</p>
                    </div>
                  </NavLink>
                </li>
              )}

              {AuthState.userData?.admin?.roles[0]?.name === "superadmin" && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    to="/admin/assistants"
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center">
                      <img src={AssistantIcon} className={`mr-5 w-8`} alt="" />{" "}
                      <p>Assistants</p>
                    </div>
                  </NavLink>
                </li>
              )}

              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("whatsapp_show")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    to="/admin/whatsapp"
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center">
                      <img src={WhatsappIcon} className={`mr-5 w-8`} alt="" />
                      <p>WhatsApp</p>
                    </div>
                  </NavLink>
                </li>
              )}
              {AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("ask")
              ) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                    to="/admin/chat"
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5" icon={faCommentAlt} />{" "}
                      <p>Inbox</p>
                    </div>
                  </NavLink>
                </li>
              )}
              {AuthState.userData?.admin?.roles[0]?.name === "superadmin" && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                    to="/admin/all-chats"
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5" icon={faCommentAlt} />{" "}
                      <p
                        className={` ${
                          state.collapse ? "hidden" : " inline-block"
                        }`}
                      >
                        All Chats
                      </p>
                    </div>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("analystics")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                    to="/admin/analytics"
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5" icon={faChartLine} />{" "}
                      <p
                        className={` ${
                          state.collapse ? "hidden" : " inline-block"
                        }`}
                      >
                        Analytics
                      </p>
                    </div>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("review")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={hadnelClickLink}
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                    to="/admin/reviews"
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5" icon={faImage} />{" "}
                      <p
                        className={` ${
                          state.collapse ? "hidden" : " inline-block"
                        }`}
                      >
                        Reviews
                      </p>
                    </div>
                  </NavLink>
                </li>
              )}

              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={hadnelClickLink}
                  to="/admin/profile"
                  className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div className="text-icon  flex w-full items-center">
                    <FontAwesomeIcon
                      className="mr-5 w-8 text-3xl font-bold"
                      icon={faUser}
                    />
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      Profile
                    </p>
                  </div>
                </NavLink>
              </li>
            </ul>
          )}

          {!admin && (
            <ul className="dash-menu-items pb-[60px] flex h-full w-full  flex-col items-center gap-5 ">
              {AuthState.userData?.student.is_student === 0 ? (
                <li className="flex h-full w-full justify-center">
                  <Link
                    onClick={hadnelClickLink}
                    to="/courses"
                    className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center justify-between px-5"
                    }  ${
                      pathname.includes("/courses") && "active"
                    }  rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                  >
                    <div className="text-icon  flex w-full items-center">
                      <img src={HomeIcon} className={`mr-5 w-8`} alt="" />
                      <p
                        className={` ${
                          state.collapse ? "hidden" : " inline-block"
                        }`}
                      >
                        Courses
                      </p>
                    </div>
                  </Link>
                </li>
              ) : (
                <>
                  {" "}
                  <li className="flex h-full w-full justify-center">
                    <Link
                      onClick={hadnelClickLink}
                      to="/home"
                      className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                        state.collapse
                          ? "items-center justify-center px-0"
                          : "items-center justify-between px-5"
                      }  ${
                        pathname === "/home" && "active"
                      }   rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                    >
                      <div className="text-icon  flex w-full items-center">
                        <img src={HomeIcon} className={`mr-5 w-8`} alt="" />
                        <p
                          className={` ${
                            state.collapse ? "hidden" : " inline-block"
                          }`}
                        >
                          Home
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li className="flex h-full w-full justify-center">
                    <Link
                      onClick={hadnelClickLink}
                      to="/lectures"
                      className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                        state.collapse
                          ? "items-center justify-center px-0"
                          : "items-center justify-between px-5"
                      }  ${
                        pathname.includes("/lectures") && "active"
                      }  rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                    >
                      <div className="text-icon  flex w-full items-center">
                        <img src={HomeIcon} className={`mr-5 w-8`} alt="" />
                        <p
                          className={` ${
                            state.collapse ? "hidden" : " inline-block"
                          }`}
                        >
                          Lectures
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li className="flex h-full w-full justify-center">
                    <Link
                      onClick={hadnelClickLink}
                      to="/courses"
                      className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                        state.collapse
                          ? "items-center justify-center px-0"
                          : "items-center justify-between px-5"
                      }  ${
                        pathname.includes("/courses") && "active"
                      }  rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                    >
                      <div className="text-icon  flex w-full items-center">
                        <img src={HomeIcon} className={`mr-5 w-8`} alt="" />
                        <p
                          className={` ${
                            state.collapse ? "hidden" : " inline-block"
                          }`}
                        >
                          Courses
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li className="flex h-full w-full justify-center">
                    <Link
                      onClick={hadnelClickLink}
                      to="/retake"
                      className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                        state.collapse
                          ? "items-center justify-center px-0"
                          : "items-center justify-between px-5"
                      }  ${
                        pathname.includes("/retake") && "active"
                      }  rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                    >
                      <div className="text-icon  flex w-full items-center">
                        <FontAwesomeIcon
                          icon={faUndo}
                          className={`mr-5 w-8`}
                          alt=""
                        />
                        <p
                          className={` ${
                            state.collapse ? "hidden" : " inline-block"
                          }`}
                        >
                          Retake
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li className="flex h-full w-full justify-center">
                    <Link
                      onClick={hadnelClickLink}
                      to="/help"
                      className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                        state.collapse
                          ? "items-center justify-center px-0"
                          : "items-center justify-between px-5"
                      }  ${
                        pathname.includes("/help") && "active"
                      }  rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                    >
                      <div className="text-icon  flex w-full items-center">
                        <img src={HelpIcon} className={`mr-5 w-8`} alt="" />
                        <p
                          className={` ${
                            state.collapse ? "hidden" : " inline-block"
                          }`}
                        >
                          Help
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li className="flex h-full w-full justify-center">
                    <Link
                      onClick={hadnelClickLink}
                      to="/top-students"
                      className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                        state.collapse
                          ? "items-center justify-center px-0"
                          : "items-center justify-between px-5"
                      }  ${
                        pathname.includes("/top-students") && "active"
                      }  rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                    >
                      <div className="text-icon  flex w-full items-center">
                        <img
                          src={TopStudentsIcon}
                          className={`mr-5 w-8`}
                          alt=""
                        />
                        <p
                          className={` ${
                            state.collapse ? "hidden" : " inline-block"
                          }`}
                        >
                          Top Students
                        </p>
                      </div>
                    </Link>
                  </li>
                  {/* <li className="flex h-full w-full justify-center">
                    <Link
                      onClick={hadnelClickLink}
                      to="/live-zoom"
                      className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                        state.collapse
                          ? "items-center justify-center px-0"
                          : "items-center justify-between px-5"
                      }  ${
                        pathname.includes("/live-zoom") && "active"
                      }  rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                    >
                      <div className="text-icon  flex w-full items-center">
                        <img src={LiveZommIcon} className={`mr-5 w-8`} alt="" />
                        <p
                          className={` ${
                            state.collapse ? "hidden" : " inline-block"
                          }`}
                        >
                          Zoom
                        </p>
                      </div>
                    </Link>
                  </li> */}
                  <li className="flex h-full w-full justify-center">
                    <Link
                      onClick={hadnelClickLink}
                      to="/exams"
                      className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                        state.collapse
                          ? "items-center justify-center px-0"
                          : "items-center justify-between px-5"
                      }  ${
                        pathname.includes("/exams") && "active"
                      }  rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                    >
                      <div className="text-icon  flex w-full items-center">
                        <img src={ExamIcon} className={`mr-5 w-8`} alt="" />
                        <p
                          className={` ${
                            state.collapse ? "hidden" : " inline-block"
                          }`}
                        >
                          Trial Exams
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li className="flex h-full w-full justify-center">
                    <Link
                      onClick={hadnelClickLink}
                      to="/exams"
                      className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                        state.collapse
                          ? "items-center justify-center px-0"
                          : "items-center justify-between px-5"
                      }  ${
                        pathname.includes("/exams") && "active"
                      }  rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                    >
                      <div className="text-icon  flex w-full items-center">
                        <img
                          src={ReservationIcon}
                          className={`mr-5 w-8`}
                          alt=""
                        />
                        <p
                          className={` ${
                            state.collapse ? "hidden" : " inline-block"
                          }`}
                        >
                          Reservation
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li className="flex h-full w-full justify-center">
                    <Link
                      onClick={hadnelClickLink}
                      to="/final-revision"
                      className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                        state.collapse
                          ? "items-center justify-center px-0"
                          : "items-center justify-between px-5"
                      }  ${
                        pathname.includes("/final-revision") && "active"
                      }  rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                    >
                      <div className="text-icon  flex w-full items-center">
                        <img
                          src={FinalRevisionIcon}
                          className={`mr-5 w-8`}
                          alt=""
                        />
                        <p
                          className={` ${
                            state.collapse ? "hidden" : " inline-block"
                          }`}
                        >
                          Last night & Final revisions
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li className="flex h-full w-full justify-center">
                    <Link
                      onClick={hadnelClickLink}
                      to="/center-revison"
                      className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                        state.collapse
                          ? "items-center justify-center px-0"
                          : "items-center justify-between px-5"
                      }  ${
                        pathname.includes("/center-revison") && "active"
                      }  rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                    >
                      <div className="text-icon  flex w-full items-center">
                        <img
                          src={CenterSessionIcon}
                          className={`mr-5 w-8`}
                          alt=""
                        />
                        <p
                          className={` ${
                            state.collapse ? "hidden" : " inline-block"
                          }`}
                        >
                          Center Revision
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li className="flex h-full w-full justify-center">
                    <Link
                      onClick={hadnelClickLink}
                      to="/final-steps-exams"
                      className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                        state.collapse
                          ? "items-center justify-center px-0"
                          : "items-center justify-between px-5"
                      }  ${
                        pathname.includes("/final-steps-exams") && "active"
                      }  rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                    >
                      <div className="text-icon  flex w-full items-center">
                        <img
                          src={FinalStepsIcon}
                          className={`mr-5 w-8`}
                          alt=""
                        />
                        <p
                          className={` ${
                            state.collapse ? "hidden" : " inline-block"
                          }`}
                        >
                          Final Steps Exams
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li className="flex h-full w-full justify-center">
                    <Link
                      onClick={hadnelClickLink}
                      to="/3d-models"
                      className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                        state.collapse
                          ? "items-center justify-center px-0"
                          : "items-center justify-between px-5"
                      }  ${
                        pathname.includes("/3d-models") && "active"
                      }  rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                    >
                      <div className="text-icon  flex w-full items-center">
                        <img src={ThreeDIcon} className={`mr-5 w-8`} alt="" />
                        <p
                          className={` ${
                            state.collapse ? "hidden" : " inline-block"
                          }`}
                        >
                          3d Models
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li className="flex h-full w-full justify-center">
                    <Link
                      onClick={hadnelClickLink}
                      to="/results"
                      className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                        state.collapse
                          ? "items-center justify-center px-0"
                          : "items-center justify-between px-5"
                      }  ${
                        pathname.includes("/results") && "active"
                      }  rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                    >
                      <div className="text-icon  flex w-full items-center">
                        <img src={ResultsIcon} className={`mr-5 w-8`} alt="" />
                        <p
                          className={` ${
                            state.collapse ? "hidden" : " inline-block"
                          }`}
                        >
                          My Results
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li className="flex h-full w-full justify-center">
                    <Link
                      onClick={hadnelClickLink}
                      to="/mozakra"
                      className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                        state.collapse
                          ? "items-center justify-center px-0"
                          : "items-center justify-between px-5"
                      }  ${
                        pathname.includes("/mozakra") && "active"
                      }  rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                    >
                      <div className="text-icon  flex w-full items-center">
                        <img src={MozakraIcon} className={`mr-5 w-8`} alt="" />
                        <p
                          className={` ${
                            state.collapse ? "hidden" : " inline-block"
                          }`}
                        >
                          Mozakra
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li className="flex h-full w-full justify-center">
                    <Link
                      onClick={hadnelClickLink}
                      to="/exam-videos"
                      className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                        state.collapse
                          ? "items-center justify-center px-0"
                          : "items-center justify-between px-5"
                      }  ${
                        pathname.includes("/exam-videos") && "active"
                      }  rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                    >
                      <div className="text-icon  flex w-full items-center">
                        <img
                          src={ExamVideosIcon}
                          className={`mr-5 w-8`}
                          alt=""
                        />
                        <p
                          className={` ${
                            state.collapse ? "hidden" : " inline-block"
                          }`}
                        >
                          Exam Videos
                        </p>
                      </div>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          )}

          {!admin ? <StudentChat /> : null}
          {/* <li className="log-out flex h-fit  w-full justify-center">
              <button
                onClick={() => handleSignOut()}
                className={`group flex h-16  w-[90%]  alg:w-11/12 ${
                  state.collapse
                    ? "items-center justify-center px-0"
                    : "items-center pr-5"
                }    rounded-3xl transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
              >
                <div className="text-icon ">
                  <FontAwesomeIcon
                    className="mr-5 w-8 rotate-180"
                    icon={faArrowRightFromBracket}
                  />
                  <p
                    className={` ${
                      state.collapse ? "hidden" : " inline-block"
                    } `}
                  >
                    Log out
                  </p>
                </div>
              </button>
            </li> */}
        </div>
      </div>
    </section>
  );
}

export default MainLayout;
