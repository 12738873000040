const UserMessage = ({
  user,
  selectedUserId,
  setSelectedUserId,
  setSelectedUserInfo,
  setShowUsersMessages,
}) => {
  const onSelectUser = (userObj) => {
    setSelectedUserId(userObj.student_id);
    setSelectedUserInfo(userObj);
    setShowUsersMessages((prev) => !prev);
  };

  return (
    <div
      className={`message flex items-align gap-2 p-3 hover:bg-blue hover:text-white  ${
        selectedUserId === user.student_id ? "!bg-secondary text-white" : ""
      } ${user.is_readed && "bg-green"}`}
      onClick={() => onSelectUser(user)}
    >
      <div className="userImage">
        <img src={user.student_img} alt="" />
      </div>
      <div className="userInfo">
        <div className="flex items-center justify-between">
          <h4 className="font-bold">{user.student_name}</h4>
          <span className="time">{user.date}</span>
        </div>
        <p>{user.message}</p>
      </div>
    </div>
  );
};

export default UserMessage;
