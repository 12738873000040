import { useCallback, useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import ChatMessageList from "./sections/chatMessageList/ChatMessageList";
import ChatView from "./sections/chatView/ChatView";
import style from "./styles/chatAdmin.module.scss";
import { useEffect } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import Analytics from "./sections/analytics/Analytics";
import ChatSettings from "./sections/settings/ChatSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";

const ChatAdmin = ({ superadmin }) => {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserInfo, setSelectedUserInfo] = useState({});
  const [refatch, setRefatch] = useState(false);
  const [chatUsers, setChatUsers] = useState([]);
  const [msgsType, setMsgsType] = useState("");

  const [chatUsersData, chatUsersDataErrors, chatUsersDataLoading] = useAxios(
    superadmin
      ? process.env.REACT_APP_SUPERADMIN_CHATS_API
      : process.env.REACT_APP_ADMIN_CHATS_API,
    "GET",
    "GET",
    refatch,
    ""
  );

  const [showUsersMessages, setShowUsersMessages] = useState(false);
  useEffect(() => {
    setChatUsers(chatUsersData ? [...chatUsersData?.data] : []);
  }, [chatUsersData]);

  useEffect(() => {
    function filterdDataOnRender() {
      const filterdData =
        msgsType === "readed"
          ? chatUsersData?.data.filter((user) => user.is_readed !== 1)
          : chatUsersData?.data.filter((user) => user.is_readed === 1);
      setChatUsers(
        msgsType === "readed" || msgsType === "unreaded"
          ? filterdData
          : chatUsersData?.data
      );
    }
    filterdDataOnRender();
  }, [chatUsersData, msgsType]);

  return (
    <section
      className={`${style.chatAdmin} h-full w-full overflow-y-auto px-10 py-10 sm:px-4`}
    >
      <HelmetTags title="Chats | Nawar Academy"></HelmetTags>
      <h2 className="w-full  text-4xl font-bold md:text-center mb-12">
        All Chats
      </h2>
      <Analytics unreadedMsg={chatUsersData?.unreaded_messages} />
      {superadmin && (
        <>
          <ChatSettings />
        </>
      )}
      <div className="lef-right-container flex h-fit w-full items-start gap-6 lg:flex-col lg:items-center lg:justify-start lg:gap-20 ">
        <div
          className={`w-1/3 sm:w-3/4 md:w-1/2 md:fixed bg-white md:top-[60px] transition-all z-10 h-full ${
            showUsersMessages ? "sm:left-[-75%] md:left-[-50%]" : "md:left-0 "
          }`}
        >
          <button
            onClick={() => setShowUsersMessages((prev) => !prev)}
            className="absolute right-[-40px] top-[60px] text-[40px] bg-white rounded-full flex items-center"
          >
            <FontAwesomeIcon
              icon={
                showUsersMessages ? faArrowAltCircleRight : faArrowAltCircleLeft
              }
            />
          </button>
          <ChatMessageList
            selectedUserId={selectedUserId}
            setSelectedUserId={setSelectedUserId}
            setSelectedUserInfo={setSelectedUserInfo}
            chatUsers={chatUsers}
            refatch={refatch}
            msgsType={msgsType}
            setMsgsType={setMsgsType}
            chatUsersDataLoading={chatUsersDataLoading}
            setShowUsersMessages={setShowUsersMessages}
          />
        </div>
        {selectedUserId !== null && (
          <ChatView
            selectedUserId={selectedUserId}
            setSelectedUserId={setSelectedUserId}
            selectedUserInfo={selectedUserInfo}
            setRefatch={setRefatch}
            refatch={refatch}
            superadmin={superadmin}
            chatUsersDataLoading={chatUsersDataLoading}
            msgsType={msgsType}
            setChatUsers={setChatUsers}
          />
        )}
      </div>
    </section>
  );
};

export default ChatAdmin;
